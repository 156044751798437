<div class="header">
  <div class="logo">
    <img  [ngClass]="{'carrier-logo' : partnerName == 'carrier' , 'airoverse-logo' : partnerName == 'airoverse',  'logo-75f': partnerName == '75f' }" src="../../../assets/images/logo.png" alt="Logo" >
  </div>
</div>
<div class="content-layout">

  <!-- Loader Container -->
  <div *ngIf="!profiles" class="loader-container">
    <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', height: '200px' }"></ngx-skeleton-loader>
  </div>
 
  <!-- Displaying the terminal graphics after loading -->
  <div *ngIf="profiles">
    <ng-container *ngFor="let profile of profiles;trackBy:profileByID">

      <!-- Displaying the accordion for each profile -->
      <puc-accordion [blockTitle]="formatZoneName(profile)" [profileId]="profile?.profile?._id"
        [showActive]="(!profile.isChild || !isModbus)" [isEquipGraphics]="profile['showEquipGraphics']"
        [isQRCode]="true" [isActive]="isModuleOnline(profile)" (displayTimeToggle)="false" (accordianState)="true"
        [isOpen]="true" [siteRef]="refs.siteRef" [showAssetButton]="true" [enableLastUpdatedTime]="false" [enableViewAssets]="profile['enableViewAssets']" [taggedFilesList]="profile['taggedFiles']">

        <!-- Loading Terminal Equip Graphics -->
        <div *ngIf="!showTerminalEquipGraphics && showLoader" class="loader-container">
          <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', height: '200px' }"></ngx-skeleton-loader>
        </div>

        <ng-container *ngIf="showTerminalEquipGraphics && profile['showEquipGraphics']">
          <span class="label">Equip Graphics</span>
          <puc-terminal-equip-graphics [profile]="profile" [multiModuleProfile]="multiModuleProfile"
            [showLastUpdated]="false" [userTempPreference]="userTempPreference" [zoneSettings]="equipGraphicData"
            [zoneHovercheckFlag]="true" [checkHyperStatProfile]="checkHyperStatProfile"
            [checkHyperStatProfile]="checkHyperStatProfile" [isDomainIntegrated]="isDomainIntegrated">
          </puc-terminal-equip-graphics>
          <puc-asset-data-table [zoneSettings]="equipGraphicData" [profile]="profile" [isZone]="true">
          </puc-asset-data-table>
        </ng-container>

        <ng-container *ngIf="!profile['showEquipGraphics']">
          <puc-asset-data-table [noSystemEquipGraphic]="true" [profile]="profile" [isZone]="true">
          </puc-asset-data-table>
        </ng-container>

        <puc-accordion class="margin-20" blockTitle="Predefined Widgets" [isOpen]="true" (accordianState)="true">

          <!-- Skeleton loader for widgets -->
          <div *ngIf="!profile.widgets">
            <ngx-skeleton-loader count="10" [theme]="{ 'border-radius': '0', height: '50px' }"></ngx-skeleton-loader>
          </div>

          <!-- When there are no widgets available, display a message -->
          <ng-container *ngIf="profile.widgets && !profile.widgets.length">
            <div class="no-widgets" id="noWidgetsText">No widgets available</div>
          </ng-container>

          <!-- Display widgets -->
          <ng-container *ngFor="let widget of profile.widgets">
            <puc-accordion [blockTitle]="widget.name" [graphId]="widget.id + profile.profile._id"
              [titleStyle]="accordionHeaderStyle.secondLevel" isOpen="true">
              <puc-widget class="m-l-40">
                <puc-graph [graphWidth]="graphWidth" [graphData]="widget" [graphId]="widget.id + profile.profile._id"
                  [linearData]="" (xAxisCords)="setXAxisCords($event)" [leftPos]="30"
                  [moduleIdentifier]="profile?.portNum" [moduleRef]="profile.profile._id">
                </puc-graph>
              </puc-widget>
            </puc-accordion>
          </ng-container>
        </puc-accordion>

      </puc-accordion>
    </ng-container>

  </div>

</div>